import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    transactions: [],
    isLoading: false
}

export const analyticSlice = createSlice({
    name: "analytic",
    initialState,
    reducers: {
        GetTransactions(state, action) {
            state.transactions = action.payload
        },
    }
})

export const {
    GetTransactions
    // IsLoading
} = analyticSlice.actions

export default analyticSlice.reducer