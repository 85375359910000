import React from 'react';

const CustomTooltip = ({ payload, label, active, text }) => {
    if (active) {
        // Предполагается, что `payload` содержит данные по умолчанию
        const formattedDate = new Date(label).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });
        const value = payload && payload.length ? payload[0].value : '';

        return (
            <div style={{ backgroundColor: '#0B0F14', border: '1px solid #1A202D', padding: '10px', borderRadius: '10px' }}>
                <p>{`Date: ${formattedDate}`}</p>
                <p>{`Meaning: ${value} ${text}`}</p>
            </div>
        );
    }

    return null;
}

export default CustomTooltip;