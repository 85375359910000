import React, { useEffect, useState } from 'react';
import {Box, Grid, Paper, Typography, FormControl, InputLabel, Select, MenuItem, InputBase} from '@mui/material';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    BarChart,
    Bar,
    ResponsiveContainer
} from 'recharts';
import AnalyticService from "../../services/AnalyticService";
import { format } from 'date-fns';
import CustomTooltip from "./CustomTooltip";
import UserCountryChart from "./UserCountryChart";
import {useDispatch, useSelector} from "react-redux";
import {IsLoading} from "../../redux/slices/userSlice";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import {visuallyHidden} from "@mui/utils";
import PropTypes from "prop-types";
import {alpha, styled} from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import {GetTransactions} from "../../redux/slices/analyticSlice";

const formatDate = (dateStr) => {
    return format(new Date(dateStr), 'dd.MM.yyyy'); // Форматируем дату в DD.MM.YYYY
};

const formatTime = (dateStr) => {
    return format(new Date(dateStr), 'HH:mm:ss'); // Форматируем дату в DD.MM.YYYY
};

const getMax = (data) => {
    if (data) {
        // Извлечение всех значений `total` из массива
        const totals = data.map(item => item.transactions || item.users || item.total);
        // Нахождение максимального значения
        return Math.max(...totals);
    }
    return 0;
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
    {
        id: 'userID',
        numeric: false,
        disablePadding: false,
        label: 'User ID',
    },
    {
        id: 'transactionDate',
        numeric: false,
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'transactionTime',
        numeric: false,
        disablePadding: false,
        label: 'Time',
    },
    {
        id: 'transactionAmount',
        numeric: false,
        disablePadding: false,
        label: 'Amount',
    },
    {
        id: 'transactionRating',
        numeric: false,
        disablePadding: false,
        label: 'Rating',
    },
    {
        id: 'transactionComment',
        numeric: false,
        disablePadding: false,
        label: 'Comment',
    },
    {
        id: 'transactionStatus',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'transactionType',
        numeric: false,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'method',
        numeric: false,
        disablePadding: false,
        label: 'Method',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        sx={{ color: "#fff" }}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        sx={{ color: "#fff", whiteSpace: "nowrap" }}
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            style={{ color: "#fff" }}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

function EnhancedTableToolbar(props) {
    const { numSelected, searchInput, setSearchInput, handleClickOpenModal, removeUser } = props;
    return (
        <Toolbar
            sx={[
                {
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                },
                numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                },
            ]}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Transactions
                </Typography>
            )}

            {/*{numSelected > 0 ? (*/}
            {/*    <>*/}
            {/*        {numSelected === 1 &&  <Tooltip title="Edit">*/}
            {/*            <IconButton onClick={handleClickOpenModal}>*/}
            {/*                <EditIcon sx={{ color: "#fff" }}/>*/}
            {/*            </IconButton>*/}
            {/*        </Tooltip>}*/}
            {/*        <Tooltip title="Delete">*/}
            {/*            <IconButton onClick={removeUser}>*/}
            {/*                <DeleteIcon sx={{color: "#fff"}}/>*/}
            {/*            </IconButton>*/}
            {/*        </Tooltip>*/}
            {/*    </>*/}

            {/*) : (*/}
            {/*    <Search>*/}
            {/*        <SearchIconWrapper>*/}
            {/*            <SearchIcon />*/}
            {/*        </SearchIconWrapper>*/}
            {/*        <StyledInputBase*/}
            {/*            placeholder="Search…"*/}
            {/*            inputProps={{ 'aria-label': 'search' }}*/}
            {/*            onChange={(e) => setSearchInput(e.target.value)}*/}
            {/*            value={searchInput}*/}
            {/*        />*/}
            {/*    </Search>*/}
            {/*)}*/}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const AnalyticGraphics = () => {
    const [data, setData] = useState({ users: [], transactions: [], totalAmounts: [], payments: [] });
    const [interval, setInterval] = useState('yesterday-today');
    const usersCount = data.users.length > 0 && getMax(data.users);
    const transactionCount = data.transactions.length > 0 && getMax(data.transactions);
    const total = data.totalAmounts.length > 0 && getMax(data.totalAmounts);
    const [formatTotal, setFormatTotal] = useState(0);
    const dispatch = useDispatch()
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchInput, setSearchInput] = useState('');
    const { transactions } = useSelector(state => state.analytic);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const date = new Date('2024-07-08T09:20:50.000Z');
    const formattedDate = format(date, 'yyyy-MM-dd HH:mm:ss');
    // const searchData = transactions.filter((transactions) => {
    //     const fullName = `${user.userFirstName} ${user.userLastName}`.toLowerCase();
    //     const searchValue = searchInput.toLowerCase();
    //     return (
    //         user.userFirstName.toLowerCase().includes(searchValue) ||
    //         user.userLastName.toLowerCase().includes(searchValue) ||
    //         fullName.includes(searchValue)
    //     );
    // });

    useEffect(() => {
        // Проверка на NaN
        if (!isNaN(parseFloat(total))) {
            const sum = parseFloat(total);
            setFormatTotal(sum);
        } else {
            console.warn('Invalid total value:', total);
            setFormatTotal(0);
        }
    }, [total]);

    const fetchAnalyticData = async () => {
        dispatch(IsLoading(true));
        try {
            const response = await AnalyticService.getAnalyticData(interval);
            setData(response.data);
        }catch (e){
            console.log(e)
        }finally {
            dispatch(IsLoading(false));
        }
    };
    const fetchTransactionsData = async () => {
        dispatch(IsLoading(true));
        try {
            const response = await AnalyticService.getTransactionsData();
            dispatch(GetTransactions(response.data));
            // setData(response.data);
        }catch (e){
            console.log(e)
        }finally {
            dispatch(IsLoading(false));
        }
    };
    useEffect(() => {
        fetchTransactionsData()
    }, []);
    useEffect(() => {
        fetchAnalyticData();
    }, [interval]);

    const handleIntervalChange = (event) => {
        setInterval(event.target.value);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = transactions.map((n) => n.transactionID);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event, id) => {

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const isSelected = (id) => selected.indexOf(id) !== -1;
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - transactions.length) : 0;
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h4" component="h1" gutterBottom>Analytical Dashboard</Typography>
            <FormControl fullWidth sx={{ mb: 3 }} className="dark-select">
                <InputLabel>Interval</InputLabel>
                <Select value={interval} onChange={handleIntervalChange}>
                    <MenuItem value="yesterday-today">Yesterday-Today</MenuItem>
                    <MenuItem value="last week-current week">Last week-Current week</MenuItem>
                    <MenuItem value="last month-current month">Last month-Current month</MenuItem>
                    <MenuItem value="last year-current year">Last year-Current year</MenuItem>
                </Select>
            </FormControl>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6} xl={3}>
                    <Paper sx={{ p: 2, background: "linear-gradient(rgb(9, 11, 17), rgba(19, 23, 32, 0.5))", color: "#fff", borderRadius: "10px", border: "1px solid #1A202D" }}>
                        <Typography variant="h6" component="h6" sx={{fontSize: "14px"}} gutterBottom>{"Number of registered users " + interval + " " + usersCount}</Typography>
                        <ResponsiveContainer width="100%" height={300} >
                            <LineChart data={data.users}>
                                <Line type="monotone" dataKey="users" stroke="#8884d8" />
                                <XAxis dataKey="date" tickFormatter={formatDate} />
                                <YAxis domain={[0, usersCount]} />
                                <Tooltip content={<CustomTooltip text="user"/>} />
                            </LineChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={3}>
                    <Paper sx={{ p: 2, background: "linear-gradient(rgb(9, 11, 17), rgba(19, 23, 32, 0.5))", color: "#fff", borderRadius: "10px", border: "1px solid #1A202D" }}>
                        <Typography variant="h6" component="h6" sx={{fontSize: "14px"}} gutterBottom>
                            {"Number of completed transactions " + interval + " " + transactionCount}
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={data.transactions}>
                            <Line type="monotone" dataKey="transactions" stroke="#82ca9d" />
                            <XAxis
                                dataKey="date"
                                tickFormatter={formatDate}
                            />
                            <YAxis domain={[0, transactionCount]}/>
                            <Tooltip content={<CustomTooltip text="transaction"/>} />
                        </LineChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={3}>
                    <Paper sx={{ p: 2,background: "linear-gradient(rgb(9, 11, 17), rgba(19, 23, 32, 0.5))", color: '#fff', borderRadius: "10px", border: "1px solid #1A202D" }}>
                        <Typography variant="h6" component="h6" sx={{ fontSize: "14px" }} gutterBottom>Payment Methods</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart
                                width={400}
                                height={300}
                                data={data.payments}
                            >
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Bar dataKey="count" fill="#8884d8" barSize={50}/>
                            </BarChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={3}>
                    <Paper sx={{ p: 2, background: "linear-gradient(rgb(9, 11, 17), rgba(19, 23, 32, 0.5))", color: "#fff", borderRadius: "10px", border: "1px solid #1A202D" }}>
                        <Typography variant="h6" component="h6" sx={{fontSize: "14px"}} gutterBottom>{"Total transaction amount " + interval + " " + total + "€"}</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart width={389} height={300} data={data.totalAmounts}>
                                <Line type="monotone" dataKey="total" stroke="#ff7300" />
                                <XAxis
                                    dataKey="date"
                                    tickFormatter={formatDate}
                                />
                                <YAxis domain={[0, formatTotal]}/>
                                <Tooltip content={<CustomTooltip text="€"/>} />
                            </LineChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
            </Grid>
            <Paper sx={{ width: '100%', mt: "24px", background: "linear-gradient(rgb(9, 11, 17), rgba(19, 23, 32, 0.5))", color: "#fff", borderRadius: "10px", border: "1px solid #1A202D" }}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    setSearchInput={setSearchInput}
                    searchInput={searchInput}
                />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={transactions.length}
                        />
                        <TableBody>
                            {transactions.length > 0
                                ?
                                transactions.map((transaction, index) => {
                                    const isItemSelected = isSelected(transaction.transactionID);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, transaction.transactionID)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={transaction.transactionID}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    sx={{color: "#fff"}}
                                                />
                                            </TableCell>
                                            <TableCell
                                                sx={{color: "#fff", whiteSpace: "nowrap"}}
                                                component="th"
                                                id={labelId}
                                                scope="transactions"
                                                padding="none"
                                            >
                                                {transaction.transactionID}
                                            </TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{transaction.transactionUser ? transaction.transactionUser : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{transaction.transactionDate ? formatDate(transaction.transactionDate) : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{transaction.transactionTime ? formatTime(transaction.transactionTime) : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{transaction.transactionAmount ? transaction.transactionAmount + "€" : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{transaction.transactionRating ? transaction.transactionRating : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{transaction.transactionComment ? transaction.transactionComment : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{transaction.transactionStatus ? transaction.transactionStatus : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{transaction.transactionType ? transaction.transactionType : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{transaction.method ? transaction.method : "-"}</TableCell>
                                        </TableRow>
                                    );
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} colspan={28} align="center">No Data</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    sx={{color: "#fff"}}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={transactions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

export default AnalyticGraphics;
