import React from 'react';
import UsersComponent from "../components/users-page-components/UsersComponent";
import {LinearProgress} from "@mui/material";
import {useSelector} from "react-redux";

function Users(props) {
    const {isLoading} = useSelector(state => state.users);
    return (
        <section className="users">
            {isLoading && <LinearProgress/>}
            <div className="container">
                <UsersComponent/>
            </div>
        </section>
    );
}

export default Users;