import {configureStore} from '@reduxjs/toolkit';
import users from "./slices/userSlice";
import analytic from "./slices/analyticSlice";
import authorization from "./slices/authorizationSlice";
export const store = configureStore({
    reducer:{
        users: users,
        analytic: analytic,
        authorization: authorization,
    }
})