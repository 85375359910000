import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isBlocked: false,
}

export const authorizationSlice = createSlice({
    name: "authorization",
    initialState,
    reducers: {
        BlockedIP: (state, action) => {
            state.isBlocked = action.payload;
        }
    }
})

export const {BlockedIP} = authorizationSlice.actions

export default authorizationSlice.reducer