import React from 'react';
import LoginModal from "../components/authorization/LoginModal";
import BlockIPModal from "../components/authorization/BlockIPModal";
import {useSelector} from "react-redux";

function AuthorizationPage(props) {
    const {isBlocked} = useSelector(state => state.authorization);
    return (
        <section>
            {!isBlocked
                ?
                <LoginModal/>
                :
                <BlockIPModal/>
            }
        </section>
    );
}

export default AuthorizationPage;