import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {forwardRef, Fragment, useEffect, useState} from "react";
import UserService from "../../services/UserService";
import {FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {useDispatch} from "react-redux";
import {GetUsers, IsLoading} from "../../redux/slices/userSlice";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserChangeDataModal({open, setOpen, userID}) {
    const handleClose = () => {
        setOpen(false);
    };
    const [changeDataUser, setChangeDataUser] = useState([]);
    const fullName = changeDataUser && `${changeDataUser.userFirstName} ${changeDataUser.userLastName}`;
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [profession, setProfession] = useState("");
    const [country, setCountry] = useState("");
    const [region, setRegion] = useState("");
    const [city, setCity] = useState("");
    const [streetLine1, setStreetLine1] = useState("")
    const [streetLine2, setStreetLine2] = useState("")
    const [welcomePhrase, setWelcomePhrase] = useState("")
    const [thankYouPhrase, setThankYouPhrase] = useState("")
    const [facebook, setFacebook] = useState("")
    const [instagram, setInstagram] = useState("")
    const [whatsapp, setWhatsapp] = useState("")
    const [linkedin, setLinkedin] = useState("")
    const [tiktok, setTiktok] = useState("")
    const [youtube, setYoutube] = useState("")
    const [role, setRole] = useState("")
    const dispatch = useDispatch()
    const getUsers = async () => {
        dispatch(IsLoading(true))
        try {
            const result = await UserService.getAllUsers()
            dispatch(GetUsers(result.data))
        } catch (err) {
            console.log(err)
        }finally {
            dispatch(IsLoading(false))
        }
    }

    useEffect(() => {
        const getChangeUserData = async () => {
            dispatch(IsLoading(true))
            try {
                if(userID.length === 1){
                    const result = await UserService.getUser({id: userID})
                    const userData = result.data[0]
                    setChangeDataUser(userData)
                    setFirstName(userData.userFirstName)
                    setLastName(userData.userLastName)
                    setBirthDate(userData.userBirthDate)
                    setEmail(userData.userEmail)
                    setPhone(userData.userPhone)
                    setCompanyName(userData.userCompanyName)
                    setProfession(userData.userProfession)
                    setCountry(userData.userCountry)
                    setRegion(userData.userRegion)
                    setCity(userData.userCity)
                    setStreetLine1(userData.userStreetLine_1)
                    setStreetLine2(userData.userStreetLine_2)
                    setWelcomePhrase(userData.userWelcomePhrase)
                    setThankYouPhrase(userData.userThankYouPhrase)
                    setFacebook(userData.userFacebook)
                    setInstagram(userData.userInstagram)
                    setWhatsapp(userData.userWhatsapp)
                    setLinkedin(userData.userLinkedin)
                    setTiktok(userData.userTiktok)
                    setYoutube(userData.userYoutube)
                    setRole(userData.userRole)
                }
            }catch (e){
                console.log(e)
            }finally {
                dispatch(IsLoading(false))
            }
        }
        getChangeUserData()
    }, [userID]);

    const saveData = async () =>{
        dispatch(IsLoading(true))
        try {
            const data = {
                userID,
                firstName,
                lastName,
                birthDate,
                email,
                phone,
                companyName,
                profession,
                country,
                region,
                city,
                streetLine1,
                streetLine2,
                welcomePhrase,
                thankYouPhrase,
                facebook,
                instagram,
                whatsapp,
                linkedin,
                tiktok,
                youtube,
                role
            }
            await UserService.saveUserData(data)
            getUsers()
            handleClose()
        }catch (e){
            console.log(e)
        }finally {
            dispatch(IsLoading(false))
        }
    }

    useEffect(() => {
        const handleEnterPress = (event) => {
            if (event.key === 'Enter') {
                saveData();
            }
        };

        document.addEventListener('keydown', handleEnterPress);
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [saveData]);

    return (
        <Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={{backgroundColor: "#212121", color: "#fff"}}>User data <span style={{fontWeight: "600"}}>{fullName}</span></DialogTitle>
                <DialogContent sx={{backgroundColor: "#212121", color: "#fff"}} className="dark-input">
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={firstName} onChange={(e) => setFirstName(e.target.value)} id="outlined-basic" label="First Name" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={lastName} onChange={(e) => setLastName(e.target.value)} id="outlined-basic" label="Last Name" variant="outlined"/>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                        <DemoContainer
                            components={['DatePicker']}
                            sx={{marginBottom: "15px"}}
                        >
                            <DatePicker
                                label="Birth Date"
                                fullWidth
                                sx={{width: "100%"}}
                                onChange={(e) => setBirthDate(e)}
                                value={dayjs(birthDate)}
                                views={['day', 'month', 'year']}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={email} onChange={(e) => setEmail(e.target.value)} id="outlined-basic" label="E-mail" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={phone} onChange={(e) => setPhone(e.target.value)} id="outlined-basic" label="Phone" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={companyName} onChange={(e) => setCompanyName(e.target.value)} id="outlined-basic" label="Company Name" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={profession} onChange={(e) => setCompanyName(e.target.value)} id="outlined-basic" label="Profession" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={country} onChange={(e) => setCountry(e.target.value)} id="outlined-basic" label="Country" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={region} onChange={(e) => setRegion(e.target.value)} id="outlined-basic" label="Region" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={city} onChange={(e) => setCity(e.target.value)} id="outlined-basic" label="City" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={streetLine1} onChange={(e) => setStreetLine1(e.target.value)} id="outlined-basic" label="Street Line 1" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={streetLine2} onChange={(e) => setStreetLine2(e.target.value)} id="outlined-basic" label="Street Line 2" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={welcomePhrase} onChange={(e) => setWelcomePhrase(e.target.value)} id="outlined-basic" label="Welcome Phrase" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={thankYouPhrase} onChange={(e) => setThankYouPhrase(e.target.value)} id="outlined-basic" label="Thank You Phrase" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={facebook} onChange={(e) => setFacebook(e.target.value)} id="outlined-basic" label="Facebook" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={instagram} onChange={(e) => setInstagram(e.target.value)} id="outlined-basic" label="Instagram" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={whatsapp} onChange={(e) => setWhatsapp(e.target.value)} id="outlined-basic" label="Whatsapp" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={linkedin} onChange={(e) => setLinkedin(e.target.value)} id="outlined-basic" label="Linkedin" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={tiktok} onChange={(e) => setTiktok(e.target.value)} id="outlined-basic" label="Tiktok" variant="outlined"/>
                    <TextField fullWidth sx={{marginBottom: "15px"}} value={youtube} onChange={(e) => setYoutube(e.target.value)} id="outlined-basic" label="Youtube" variant="outlined"/>
                    {/*<TextField fullWidth sx={{marginBottom: "15px"}} value={role} onChange={(e) => setRole(e.target.value)} id="outlined-basic" label="Role" variant="outlined"/>*/}
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Role</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={role}
                            label="Age"
                            onChange={(e) => setRole(e.target.value)}
                        >
                            <MenuItem value="USER">USER</MenuItem>
                            <MenuItem value="ADMIN">ADMIN</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions sx={{backgroundColor: "#212121", color: "#fff"}}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={saveData}>Save</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
