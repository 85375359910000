import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    users: [],
    isLoading: false,
    user: [],
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        addUser: (state, action) => {
            // state.user = [...state.user, action.payload];
            state.user = action.payload;
        },
        GetUsers(state, action) {
            state.users = action.payload
        },
        IsLoading(state, action) {
            state.isLoading = action.payload
        }
    }
})

export const {
    GetUsers,
    IsLoading,
    addUser
} = userSlice.actions

export default userSlice.reducer