import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Fragment, useEffect, useState} from "react";
import { ImBlocked } from "react-icons/im";
import {Link} from "react-router-dom";

export default function BlockIPModal() {
    const [isPassShow, setIsPassShow] = useState(false);
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    useEffect(() => {
        handleClickOpen()
    }, []);
    return (
        <Fragment>
            <Dialog
                open={open}
                PaperProps={{
                    component: 'div',
                    sx:{backgroundColor: "#0D1018", color: "#fff", display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center'},
                }}
            >
                <DialogTitle><ImBlocked color="#ff0000" size={100} /></DialogTitle>
                <DialogContent sx={{textAlign: 'center'}}>
                    ¡No tienes acceso! Tu IP está bloqueada. Para desbloquear IP, comuníquese con el soporte técnico
                </DialogContent>
                <DialogActions sx={{padding: '0 20px 24px'}}>
                    <Link style={{textTransform: "lowercase", color: "#fff", textDecoration: "underline"}} to="https://tipssi.net">TipsSi.net</Link>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}