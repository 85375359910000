import './App.css';
import MenuComponent from "./components/menu/MenuComponent"
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Users from "./pages/Users";
import * as React from "react";
import Analytic from "./pages/Analytic";
import AuthorizationPage from "./pages/AuthorizationPage";
import {useEffect} from "react";
import AuthService from "./services/AuthService";
import {BlockedIP} from "./redux/slices/authorizationSlice";
import {useDispatch} from "react-redux";

function App() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const checkIP = async () => {
        try {
            await AuthService.checkForBlocking();
        }catch (error) {
            if(error.response?.data?.message === "You don't have access! Your IP has been blocked."){
                dispatch(BlockedIP(true))
            }
        }
    }
    const redirect = () => {
        if(!localStorage.getItem('user')){
            navigate('/');
        }
    }
    useEffect(() => {
        checkIP();
        redirect();
        const handleBeforeUnload = () => {
            localStorage.removeItem('user'); // Удаление элемента из localStorage
        };
        // Добавляем обработчик события перед закрытием
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Удаляем обработчик события при размонтировании компонента
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    return (
        <div className="App">
            <div className="d-flex align-items-start">
                {location.pathname !== "/" && <MenuComponent/>}
                <Routes>
                    <Route path="/" element={<AuthorizationPage/>}/>
                    <Route path="/users" element={<Users/>}/>
                    <Route path="/analytic" element={<Analytic/>}/>
                </Routes>
            </div>
        </div>
    );
}

export default App;
