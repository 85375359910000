import React from 'react';
import AnalyticGraphics from "../components/analytic-page-components/AnalyticGraphics";
import {LinearProgress} from "@mui/material";
import {useSelector} from "react-redux";

function Analytic(props) {
    const {isLoading} = useSelector(state => state.users);

    return (
        <section className="analytics">
            {isLoading && <LinearProgress/>}
            <div className="container">
                <AnalyticGraphics/>
            </div>
        </section>
    );
}

export default Analytic;