import $api from "../http";

export default class AnalyticService {
    static async getAnalyticData(interval) {
        return await $api.get(`analytics?interval=${interval}`);
    }

    static async getTransactionsData() {
        return await $api.get('transactions');
    }
}