import * as React from 'react';
import style from './UsersComponent.module.scss';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { visuallyHidden } from '@mui/utils';
import { useEffect, useState } from "react";
import UserService from "../../services/UserService";
import { useDispatch, useSelector } from "react-redux";
import {GetUsers, IsLoading} from "../../redux/slices/userSlice";
import EditIcon from '@mui/icons-material/Edit';
import {Button, InputBase} from "@mui/material";
import UserChangeDataModal from "../user-change-data-modal/UserChangeDataModal";
import DeleteAccountModal from "../delete-account-modal/DeleteAccountModal";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { MdBlock } from "react-icons/md";
import { CgUnblock } from "react-icons/cg";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
    {
        id: 'firstName',
        numeric: false,
        disablePadding: false,
        label: 'First Name',
    },
    {
        id: 'lastName',
        numeric: false,
        disablePadding: false,
        label: 'Last Name',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'E-mail',
    },
    {
        id: 'phone',
        numeric: false,
        disablePadding: false,
        label: 'Phone',
    },
    {
        id: 'birthDate',
        numeric: false,
        disablePadding: false,
        label: 'Birth Date',
    },
    {
        id: 'profession',
        numeric: false,
        disablePadding: false,
        label: 'Profession',
    },
    {
        id: 'companyName',
        numeric: false,
        disablePadding: false,
        label: 'Company Name',
    },
    {
        id: 'country',
        numeric: false,
        disablePadding: false,
        label: 'Country',
    },
    {
        id: 'region',
        numeric: false,
        disablePadding: false,
        label: 'Region',
    },
    {
        id: 'city',
        numeric: false,
        disablePadding: false,
        label: 'City',
    },
    {
        id: 'streetLine1',
        numeric: false,
        disablePadding: false,
        label: 'Street Line 1',
    },
    {
        id: 'streetLine2',
        numeric: false,
        disablePadding: false,
        label: 'Street Line 2',
    },
    {
        id: 'welcomePhrase',
        numeric: false,
        disablePadding: false,
        label: 'Welcome Phrase',
    },
    {
        id: 'thankYouPhrase',
        numeric: false,
        disablePadding: false,
        label: 'Thank You Phrase',
    },
    {
        id: 'facebookUrl',
        numeric: false,
        disablePadding: false,
        label: 'Facebook',
    },
    {
        id: 'instagramUrl',
        numeric: false,
        disablePadding: false,
        label: 'Instagram',
    },
    {
        id: 'whatsappPhone',
        numeric: false,
        disablePadding: false,
        label: 'Whatsapp',
    },
    {
        id: 'linkedinUrl',
        numeric: false,
        disablePadding: false,
        label: 'Linkedin',
    },
    {
        id: 'tiktok',
        numeric: false,
        disablePadding: false,
        label: 'TikTok',
    },
    {
        id: 'youtube',
        numeric: false,
        disablePadding: false,
        label: 'Youtube',
    },
    {
        id: 'role',
        numeric: false,
        disablePadding: false,
        label: 'Role',
    },
    {
        id: 'activated',
        numeric: false,
        disablePadding: false,
        label: 'Activated',
    },
    {
        id: 'emailConfirm',
        numeric: false,
        disablePadding: false,
        label: 'Email Confirm',
    },
    {
        id: 'verified',
        numeric: false,
        disablePadding: false,
        label: 'Verified',
    },
    {
        id: 'total',
        numeric: false,
        disablePadding: false,
        label: 'Total',
    },
    {
        id: 'howMuchIsLeft',
        numeric: false,
        disablePadding: false,
        label: 'How Much Is Left',
    },
    {
        id: 'isBlocked',
        numeric: false,
        disablePadding: false,
        label: 'Block user',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        sx={{ color: "#fff" }}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        sx={{ color: "#fff", whiteSpace: "nowrap" }}
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            style={{ color: "#fff" }}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

function EnhancedTableToolbar(props) {
    const { numSelected, searchInput, setSearchInput, handleClickOpenModal, removeUser, blockUser, unblockUser } = props;
    return (
        <Toolbar
            sx={[
                {
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                },
                numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                },
            ]}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Users
                </Typography>
            )}

            {numSelected > 0 ? (
                <>
                    {numSelected === 1 &&  <Tooltip title="Edit">
                        <IconButton onClick={handleClickOpenModal}>
                            <EditIcon sx={{ color: "#fff" }}/>
                        </IconButton>
                    </Tooltip>}
                    <Tooltip title="Delete">
                        <IconButton onClick={removeUser}>
                            <DeleteIcon sx={{color: "#fff"}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Block">
                        <IconButton onClick={blockUser}>
                            <MdBlock color="#ff0000" fontSize={30}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Unblock">
                        <IconButton onClick={unblockUser}>
                            <CgUnblock color="#37ff00" fontSize={33}/>
                        </IconButton>
                    </Tooltip>
                </>

            ) : (
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(e) => setSearchInput(e.target.value)}
                        value={searchInput}
                    />
                </Search>
            )}

        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const dispatch = useDispatch()
    const { users } = useSelector(state => state.users);
    const [searchInput, setSearchInput] = useState('');
    const [openChangeModal, setOpenChangeModal] = useState(false);
    const [openDeleteAccountModal, setDeleteAccountModal] = useState(false);
    const searchData = users.filter((user) => {
        const fullName = `${user.userFirstName} ${user.userLastName}`.toLowerCase();
        const searchValue = searchInput.toLowerCase();
        return (
            user.userFirstName.toLowerCase().includes(searchValue) ||
            user.userLastName.toLowerCase().includes(searchValue) ||
            fullName.includes(searchValue)
        );
    });

    const getUsers = async () => {
        dispatch(IsLoading(true))
        try {
            const result = await UserService.getAllUsers()
            dispatch(GetUsers(result.data))
        } catch (err) {
            console.log(err)
        }finally {
            dispatch(IsLoading(false))
        }
    }

    useEffect(() => {
        getUsers()
    }, [])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = searchData.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - searchData.length) : 0;

    const handleClickOpenModal = () => {
        setOpenChangeModal(true);
    };

    const handleClickOpenDeleteAccountModal = () => {
        setDeleteAccountModal(true);
    };

    const handleBlockUser = async () => {
        dispatch(IsLoading(true))
        try {
            const result = await UserService.blockUser({userID: selected})
            getUsers()
        } catch (err) {
            console.log(err)
        }finally {
            dispatch(IsLoading(false))
        }
    }

    const handleUnBlockUser = async () => {
        dispatch(IsLoading(true))
        try {
            const result = await UserService.unBlockUser({userID: selected})
            getUsers()
        } catch (err) {
            console.log(err)
        }finally {
            dispatch(IsLoading(false))
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            {selected.length > 0 &&
                <UserChangeDataModal
                    setOpen={setOpenChangeModal}
                    open={openChangeModal}
                    userID={selected}
                />
            }
            <DeleteAccountModal getUsers={getUsers} userID={selected} open={openDeleteAccountModal} setOpen={setDeleteAccountModal}/>
            <Paper sx={{ width: '100%', mb: 2, background: "linear-gradient(rgb(9, 11, 17), rgba(19, 23, 32, 0.5))", color: "#fff", borderRadius: "10px", border: "1px solid #1A202D" }}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    setSearchInput={setSearchInput}
                    searchInput={searchInput}
                    handleClickOpenModal={handleClickOpenModal}
                    removeUser={handleClickOpenDeleteAccountModal}
                    blockUser={handleBlockUser}
                    unblockUser={handleUnBlockUser}
                />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={searchData.length}
                        />
                        <TableBody>
                            {searchData.length > 0
                                ?
                                searchData.map((user, index) => {
                                    const isItemSelected = isSelected(user.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, user.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={user.id}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    sx={{color: "#fff"}}
                                                />
                                            </TableCell>
                                            <TableCell
                                                sx={{color: "#fff", whiteSpace: "nowrap"}}
                                                component="th"
                                                id={labelId}
                                                scope="user"
                                                padding="none"
                                            >
                                                {user.id}
                                            </TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userFirstName ? user.userFirstName : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userLastName ? user.userLastName : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userEmail ? user.userEmail : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userPhone ? user.userPhone : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userBirthDate ? user.userBirthDate : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userProfession ? user.userProfession : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userCompanyName ? user.userCompanyName : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userCountry ? user.userCountry : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userRegion ? user.userRegion : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userCity ? user.userCity : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userStreetLine_1 ? user.userStreetLine_1 : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userStreetLine_2 ? user.userStreetLine_2 : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userWelcomePhrase ? user.userWelcomePhrase : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userThankYouPhrase ? user.userThankYouPhrase : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userFacebook ? user.userFacebook : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userInstagram ? user.userInstagram : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userWhatsapp ? user.userWhatsapp : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userLinkedin ? user.userLinkedin : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userTikTok ? user.userTikTok : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userYoutube ? user.userYoutube : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userRole ? user.userRole : "-"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userIsActivated === 1 ? "Activated" : "Don't activated"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userEmailConfirm === 1 ? "Email confirmed" : "Email don't confirmed"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.userVerified === 1 ? "Verified" : "Don't verified"}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.totalAmount ? user.totalAmount : 0}</TableCell>
                                            <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} align="left">{user.howMuchIsLeft ? user.howMuchIsLeft : 0}</TableCell>
                                            <TableCell sx={{whiteSpace: "nowrap"}} align="left">{user.isBlocked ? <MdBlock color="#ff0000" fontSize={30}/> : <CgUnblock color="#37ff00" fontSize={30}/>}</TableCell>
                                        </TableRow>
                                );
                            })
                                :
                                <TableRow>
                                    <TableCell sx={{color: "#fff", whiteSpace: "nowrap"}} colspan={28} align="center">No Data</TableCell>
                                </TableRow>
                            }
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                        color: "#fff"
                                    }}
                                >
                                    Empty
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    sx={{color: "#fff"}}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={searchData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
