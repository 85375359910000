import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { GrTransaction } from "react-icons/gr";
import MailIcon from '@mui/icons-material/Mail';
import GroupIcon from '@mui/icons-material/Group';
import {useEffect, useState} from "react";
import {Avatar, Button, LinearProgress} from "@mui/material";
import defaultAvatar from "../../assets/default-avatar.png"
import {Link, useNavigate, useNavigation} from "react-router-dom";
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    backgroundColor: "#212121",
    color: "#fff"
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    backgroundColor: "#212121",
    color: "#fff"
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: "#212121",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
    backgroundColor: "#212121",
    zIndex: theme.zIndex.drawer + 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    variants: [
        {
            props: ({ open }) => open,
            style: {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
    ],
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        variants: [
            {
                props: ({ open }) => open,
                style: {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme),
                },
            },
            {
                props: ({ open }) => !open,
                style: {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': closedMixin(theme),
                },
            },
        ],
    }),
);

export default function MiniDrawer() {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const id = localStorage.getItem('user');
    const [user, setUser] = useState([]);
    const {userFirstName, userLastName, userImageName} = user;
    const name = `${userFirstName} ${userLastName}`;
    const avatar = `https://tipssi.net/media/${userImageName}`;
    const fetchUser = async () => {
        try {
            const response = await UserService.getUser({id});
            if(response.data.length > 0){
                setUser(response.data[0]);
            }
        }catch (e){
            console.log(e)
        }
    }
    useEffect(() => {
        fetchUser()
    }, [id]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{background: "linear-gradient(rgb(9, 11, 17), rgba(19, 23, 32, 0.5))", border: "1px solid #1A202D"}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={[
                            {
                                marginRight: 5,
                            },
                            open && { display: 'none' },
                        ]}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
                <Box sx={{ display: 'flex', alignItems: "center", gap: "10px"}}>
                    <span>{name}</span>
                    <Avatar alt="avatar" src={avatar ? avatar : defaultAvatar} sx={{mr: "24px"}}/>
                </Box>
            </AppBar>
            <Drawer variant="permanent" open={open} sx={{background: "linear-gradient(rgb(9, 11, 17), rgba(19, 23, 32, 0.5))", borderRadius: "10px", border: "1px solid #1A202D"}}>
                <DrawerHeader sx={{background: "linear-gradient(rgb(9, 11, 17), rgba(19, 23, 32, 0.5))"}}>
                    <IconButton onClick={handleDrawerClose}
                        sx={{color: "#fff"}}
                    >
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {['Users', 'Analytic'].map((text, index) => (
                        <ListItem key={text} disablePadding sx={{display: 'block'}}>
                            <Link to={text.toLowerCase()}>
                                <Button
                                    fullWidth
                                    sx={[
                                        {
                                            color: "#fff",
                                            textTransform: "capitalize",
                                            minHeight: 48,
                                            px: 2.5,
                                        },
                                        open
                                            ? {
                                                justifyContent: 'initial',
                                            }
                                            : {
                                                justifyContent: 'center',
                                            },
                                    ]}
                                >
                                    <ListItemIcon
                                        sx={[
                                            {
                                                minWidth: 0,
                                                justifyContent: 'center',
                                                color: "#fff"
                                            },
                                            open
                                                ? {
                                                    mr: 3,
                                                }
                                                : {
                                                    mr: 'auto',
                                                },
                                        ]}
                                    >
                                        {text === "Users"
                                            ? <GroupIcon/>
                                            : text === "Transactions"
                                                ? <GrTransaction fontSize="22px"/>
                                                : text === "Analytic"
                                                    ? <SignalCellularAltIcon/>
                                                    : <MailIcon/>
                                        }
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={text}
                                        sx={[
                                            {flex: "0 1 auto"},
                                            open
                                                ? {
                                                    opacity: 1,
                                                }
                                                : {
                                                    opacity: 0,
                                                },
                                        ]}
                                    />
                                </Button>
                            </Link>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </Box>
    );
}
