import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {forwardRef, Fragment, useState} from "react";
import UserService from "../../services/UserService";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteAccountModal({setOpen, open, getUsers, userID}) {
    const handleClose = () => {
        setOpen(false);
    };
    const removeUser = async () => {
        try {
            await UserService.deleteUser({userID})
            getUsers()
            handleClose()
        }catch (e) {
            console.log(e)
        }
    }
    return (
        <Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={{backgroundColor: "#212121", color: "#fff"}}>Warning!!!</DialogTitle>
                <DialogContent sx={{backgroundColor: "#212121", color: "#fff"}}>Are you sure you want to <span style={{color: "#ff0000"}}>delete</span> the selected account(s)?</DialogContent>
                <DialogActions sx={{backgroundColor: "#212121", color: "#fff"}}>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={removeUser}>Yes</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
