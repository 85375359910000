import $api from "../http";

export default class AuthService {
    static async login(email, password) {
        return $api.post('login', {email, password})
    }

    static async checkForBlocking() {
        return $api.get('check-ip-for-blocking')
    }
}